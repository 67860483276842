import React from 'react';
import PropTypes from 'prop-types';

const Yahoo = ({ height, width, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1792 1792"
      height={height}
      width={width}
    >
      <path fill="none" d="M-1-1h582v402H-1z" />
      <g>
        <path
          d="M987 957l13 707c-41.3-7.3-76.3-11-105-11-27.3 0-62.3 3.7-105 11l13-707c-26.7-46-82.8-144.5-168.5-295.5S476.7 385.7 418 287 299 92.7 237 0c38.7 10 74.7 15 108 15 28.7 0 65.7-5 111-15 42 74 86.5 150.5 133.5 229.5s102.7 171.2 167 276.5S867 687 895 733c24.7-40.7 61.2-99.8 109.5-177.5s87.5-141 117.5-190 65-107.7 105-176S1302.7 58 1334 0c36 9.3 71.7 14 107 14 37.3 0 75.3-4.7 114-14-18.7 26-38.7 55.5-60 88.5s-37.8 59.2-49.5 78.5-30.5 51.3-56.5 96-42.3 72.7-49 84c-97.3 165.3-215 368.7-353 610z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

Yahoo.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

Yahoo.defaultProps = {
  height: 100,
  width: 100,
  fill: '#4a089f',
};

export default Yahoo;
