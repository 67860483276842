import React from 'react';

import _navStyles from './nav.module.scss';

const Nav = ({ hideLinks }) => {
  if (hideLinks) return;

  return (
    <nav className={`${_navStyles.links}`}>
      <a
        href="https://kalanso.com/pages/contact-us"
        className={`${_navStyles.link}`}
      >
        Have Questions? Write Us
      </a>
      <a
        href="https://app.termly.io/document/privacy-policy/693c0cb3-80e2-4bb2-b52b-937fdbe1da21"
        className={_navStyles.link}
      >
        Privacy Policy
      </a>
      <a
        href="https://app.termly.io/document/terms-and-conditions/9cf3d382-192b-4ade-8bd6-5253ca98c89a"
        className={_navStyles.link}
      >
        Terms & Conditions
      </a>
      <a
        href="https://app.termly.io/document/disclaimer/13bf21bc-eada-40e5-8c34-5c9084c5a41e"
        className={_navStyles.link}
      >
        Disclaimer
      </a>
    </nav>
  );
};

export default Nav;