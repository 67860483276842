import React from 'react';

import {
  LogoLeftAligned as MobileLogo,
  LogoCentered as DesktopLogo,
} from 'kalanso-plugin-logos';

import _utils from '../../styles/utils.module.scss';

const Logo = () => {
  return (
    <>
      <MobileLogo className={_utils.hiddenAboveTablet} fill="#000000" />
      <DesktopLogo className={_utils.hiddenBelowTablet} fill="#000000" />
    </>
  );
};
export default Logo;
