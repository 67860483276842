import React from 'react';

import _copyrightStyles from './copyright.module.scss';

const Copyright = () => {
  return (
    <p className={`${_copyrightStyles.container}`}>
      Copyright {new Date().getFullYear()} Kalanso, LLC. All rights reserved.
    </p>
  );
};

export default Copyright;
