import React from 'react';
import PropTypes from 'prop-types';

// This one has some complex fills so we'll leave them hard coded
const Google = ({ height, width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" height={height} width={width}>
      <path fill="none" d="M0 0h128v128H0z" />
      <path
        d="M27.6 64c0-4.2.7-8.1 1.9-11.9L7.9 35.6C3.7 44.2 1.4 53.8 1.4 64S3.8 83.8 8 92.3l21.6-16.5c-1.3-3.7-2-7.7-2-11.8"
        fill="#fbbc05"
      />
      <path
        d="M65.5 26.2c9 0 17.2 3.2 23.6 8.4L107.7 16C96.3 6.1 81.8 0 65.5 0 40.1 0 18.4 14.5 7.9 35.6l21.6 16.5c5-15.1 19.1-25.9 36-25.9"
        fill="#ea4335"
      />
      <path
        d="M65.5 101.8c-16.8 0-31-10.9-35.9-25.9L7.9 92.4C18.4 113.5 40.1 128 65.5 128c15.6 0 30.6-5.6 41.8-16L86.7 96.2c-5.7 3.7-13 5.6-21.2 5.6"
        fill="#34a853"
      />
      <path
        d="M126.6 64c0-3.8-.6-7.9-1.5-11.6H65.5v24.7h34.4c-1.7 8.4-6.4 14.9-13.1 19.1l20.5 15.8c11.7-10.9 19.3-27.1 19.3-48"
        fill="#4285f4"
      />
    </svg>
  );
};

Google.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Google.defaultProps = {
  height: 100,
  width: 100,
};

export default Google;
