import React from 'react';
import PropTypes from 'prop-types';

const OutlookCom = ({ height, width, fill }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 1024 1024"
      height={height}
      width={width}
    >
      <path fill="none" d="M-1-1h582v402H-1z" />
      <g>
        <path
          fill={fill}
          d="M644.9 764.4V492.7c19.3 13.3 37.4 25.6 55.4 38 16.2 11.1 18.9 11 35.6-.1 92.5-61.5 185-122.9 277.5-184.4 2.8-1.9 5.7-3.6 9.8-6.1.3 4.2.8 7.4.8 10.5 0 118.2.1 236.4 0 354.7 0 39.3-19.9 59.1-59 59.1H644.9zM645.6 216.9h336.2c19.4 0 34.4 11.9 40.5 30.5 2.7 8.2 0 12.2-6.7 16.6-84.1 56.3-168 112.9-252 169.4-12 8.1-24.1 15.9-35.9 24.3-6.4 4.6-12.2 4.8-18.8.5-19.2-12.4-38.7-24.2-57.8-36.7-3.2-2.1-6.5-6.9-6.5-10.4-.3-63.5-.2-127-.1-190.5 0-.8.4-1.5 1.1-3.7zM596.7 1024C397.6 982.3 199.3 940.8.3 899.2v-13.1c0-252.5.1-505-.3-757.5 0-10.5 2.8-14.1 13-16 159.1-30.4 318.2-61.2 477.3-92C525.4 13.7 560.5 7 596.7 0v1024zM438 511c-.4-41.6-6.1-80-26.7-114.9-16.6-28.1-39-49.4-71.3-58.6-62.8-17.9-122.8 11.5-152.4 75-18.9 40.7-23 83.9-19.3 128.1 3.2 38.3 14.3 73.7 39 104 49.1 60.2 138.4 60.6 188.2.8 32.9-39.6 41.9-86.7 42.5-134.4z"
        />
        <path
          fill={fill}
          d="M232.9 511.9c-.1-29.7 3.9-58.4 20.7-83.8 15.5-23.5 39-33.1 64.9-26.6 20.1 5 32.3 19.3 40.4 37.2 13.8 30.7 16.2 63.3 12.1 95.9-2.3 18.1-7.5 36.5-14.8 53.2-9.5 21.6-27.8 34-52.2 34.5-24.6.5-41.7-12.2-53.6-32.6-14.1-23.9-17.5-50.5-17.5-77.8z"
        />
      </g>
    </svg>
  );
};

OutlookCom.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string
}

OutlookCom.defaultProps = {
  height: 100,
  width: 100,
  fill: "#0072c6"
}

export default OutlookCom;
