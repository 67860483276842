import React from 'react';
import PropTypes from 'prop-types';

const Apple = ({ height, width, fill }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 56.7 56.7"
      height={height}
      width={width}
    >
      <path fill="none" d="M-1-1h582v402H-1z" />
      <g>
        <path
          fill={fill}
          d="M41.8 30.5c-.1-6.2 5.1-9.2 5.3-9.4-2.9-4.2-7.4-4.8-9-4.9-3.8-.4-7.5 2.3-9.4 2.3-1.9 0-4.9-2.2-8.1-2.1-4.2.1-8 2.4-10.2 6.2-4.4 7.5-1.1 18.6 3.1 24.7 2.1 3 4.5 6.3 7.8 6.2 3.1-.1 4.3-2 8.1-2s4.8 2 8.1 2c3.4-.1 5.5-3 7.5-6 2.4-3.5 3.3-6.8 3.4-7-.1-.1-6.6-2.5-6.6-10zM35.6 12.2c1.7-2.1 2.9-5 2.6-7.9-2.5.1-5.5 1.6-7.2 3.7-1.6 1.8-3 4.8-2.6 7.6 2.6.3 5.5-1.3 7.2-3.4z"
        />
      </g>
    </svg>
  );
};

Apple.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

Apple.defaultProps = {
  height: 100,
  width: 100,
  fill: '#606060',
};

export default Apple;
