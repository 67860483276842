import React from 'react';

import _postscriptStyles from './postscript.module.scss';

const Postscript = () => {
  return (
    <aside className={`${_postscriptStyles.container}`}>
      <p className={`${_postscriptStyles.body}`}>
        P.S. Most people feel guilty when their plants don't make it. What they
        don't know is usually it's because of bad soil. At Kalanso Biochar, we
        make soil amendments so you can grow any plant you want with confidence
        and have gardens and landscapes that thrive.
      </p>
    </aside>
  );
};

export default Postscript;
