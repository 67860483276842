import React from 'react';
import PropTypes from 'prop-types';

const Office365 = ({ height, width, fill }) => {
  return (
    <svg viewBox="0 0 2075 2499.8" xmlns="http://www.w3.org/2000/svg" height={height} width={width}>
      <path
        d="M0 2016.6V496.8L1344.4 0 2075 233.7v2045.9l-730.6 220.3L0 2016.6l1344.4 161.8V409.2L467.6 613.8v1198.3z"
        fill={fill}
      />
    </svg>
  );
};

Office365.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

Office365.defaultProps = {
  height: 100,
  width: 100,
  fill: '#eb3c00',
};

export default Office365;
