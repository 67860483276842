import React from 'react';
import PropTypes from 'prop-types';

import Logo from './Logo';
import Nav from './Nav';
import Copyright from './Copyright';
import Postscript from './Postscript';

import _footerStyles from './footer.module.scss';

const Footer = ({ hideLinks, hidePostscript }) => {
  return (
    <footer className={`${_footerStyles.body}`}>
      <div className={`${_footerStyles.legal}`}>
        <Logo />
        <Copyright />
      </div>
      {!hideLinks && <Nav />}
      {!hidePostscript && <Postscript />}
    </footer>
  );
};

Footer.propTypes = {
  hideLinks: PropTypes.bool,
  hidePostscript: PropTypes.bool,
};

Footer.defaultProps = {
  hideLinks: false,
  hidePostscript: false,
};

export default Footer;
